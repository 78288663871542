import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Tiles from "../components/Tiles";
import Facebook from "../components/Facebook";

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;
    const tiles = [
      {
        image: "/media/img/laboratory.jpg",
        title: "Locations & Hours",
        slug: "/locations",
        description:
          "View our strategically located collection rooms across the Bay of Plenty, Waikato and Lakes regions.",
      },
      {
        image: "/media/img/lab-testing.jpg",
        title: "Patients",
        slug: "/patients",
        description:
          "Answers to questions frequently asked by our patients. View instructions and pricing for various tests and find out if you need an appointment.",
      },
      {
        image: "/media/img/computer.jpg",
        title: "Providers",
        slug: "/providers",
        description:
          "Find resources such as antibiotic profiles, laboratory test guides, swab charts, and order supplies.",
      },
      {
        image: "/media/img/analyzer.jpg",
        title: "About",
        slug: "/about",
        description:
          "Find out more about Pathlab. Uncover our history. Learn about our values and commitment to excellence.",
      },
    ];
    const fbLinks = [
      {
        image: "/media/img/cameron-road.jpg",
        title: "Pathlab Cameron Road",
        url: "https://www.facebook.com/Pathlab-Cameron-Rd-Tauranga-104376252086234",
        description:
          "See our Facebook page for updates on service status at Pathlab Cameron Road.",
      },
      {
        image: "/media/img/tristram-street.jpg",
        title: "Pathlab Tristram Street",
        url: "https://www.facebook.com/Pathlab-Tristram-St-Hamilton-100278552500220",
        description:
          "See our Facebook page for updates on service status at Pathlab Tristram Street.",
      },
    ];
    return (
      <Layout>
        <section className="section">
          <div className="container content-border">
            {/* <div className="covid-banner has-text-centered">
              <Link className="" to="/covid">
                Need COVID Information? <u>Learn More Here</u>
              </Link>
            </div>
            <div className="covid-booking has-text-centered">
              <Link className="" to="/covid#covid-travel">
                <strong>
                  Flying Internationally? <u>Book your test here.</u>
                </strong>
              </Link>
            </div> */}

            {/* <div className="support-banner has-text-centered">
              <h1>Bay Of Plenty Collection Services Staffing Crisis</h1>
              <div>
                <p>
                  We are most grateful for your support and understanding while
                  we work through the unprecedented staffing issues we are
                  experiencing in the Bay of Plenty.
                </p>
                <p>
                  We sincerely apologise for the significant impact this is
                  having on wait times at our collection facilities.
                </p>
              </div>
            </div> */}
            {/* <div className="open-banner has-text-centered">
              <h1>PHLEBOTOMY OPEN DAY 2023 Hamilton</h1>
              <div>
                <p>Does the art of taking blood interest you?</p>
                <span>(phlebotomy)</span>
                <p>
                  Do you want to learn more about it? (You can earn while you
                  learn).
                </p>
                <p>
                  For all the information about our open day, register your
                  interest <a href="/open">HERE!</a>
                </p>
              </div>
            </div> */}

            <div className="columns">
              <div className="column is-8 is-offset-2">
                {/* <a href="/locations">
                  <div className="content basic-border news-item has-text-centered banner news-header">
                    <h1 className="has-text-primary">
                      Statutory and Public Holidays
                    </h1>
                    <div className="has-text-weight-bold is-size-5">
                      <p>
                        Pathlab collection facilities are closed on all
                        statutory and public holidays.
                      </p>
                      <p>
                        Please check our{" "}
                        <u className="has-text-primary">locations</u> page for
                        our opening days and hours.
                      </p>
                    </div>
                  </div>
                </a> */}
                {/* <a href="/open">
                  <div className="content basic-border news-item has-text-centered banner news-header">
                    <h1 className="has-text-primary">
                      Laboratory Open Day, Tauranga
                    </h1>
                    <div className="has-text-weight-bold is-size-5">
                      <p>Does science captivate you?</p>
                      <p>
                        What about 'earning as you're learning' in a medical
                        laboratory?
                      </p>
                      <p>
                        <u className="has-text-primary">
                          Register here for all the details about this unique
                          opportunity.
                        </u>
                      </p>
                    </div>
                  </div>
                </a> */}
                {/* <a href="/strike">
                  <div className="content basic-border news-item has-text-centered banner news-header action-banner">
                    <h1 className="action-header">
                      Pending Industrial Strike Action
                    </h1>
                    <h1 className="action-header">
                      Wednesday 5th & Friday 7th February
                    </h1>
                    <div className="has-text-weight-bold is-size-5">
                      <p>
                        Services we will be able to provide during this period
                        can be found by clicking this banner.
                      </p>
                      <p>
                        Room closure information will be highlighted on our
                        locations page.
                      </p>
                    </div>
                  </div>
                </a> */}
                {/* <div className="content basic-border news-item has-text-centered banner news-header action-banner">
                  <h1 className="action-header">Industrial Strike Action</h1>
                  <div className="has-text-weight-bold is-size-4">
                    <p className="action-header">
                      Wednesday 5th & Friday 7th February
                    </p>
                  </div>
                  <div className="has-text-weight-bold is-size-5">
                    <p>
                      Services we can provide during this period:{" "}
                      <a className="has-text-primary" href="/strike/">
                        www.pathlab.co.nz/strike
                      </a>
                    </p>
                    <p>
                      Room closure information{" "}
                      <a className="has-text-primary" href="/locations/">
                        www.pathlab.co.nz/locations
                      </a>
                    </p>
                  </div>
                </div> */}
                <div className="columns is-desktop">
                  <div className="column is-6-desktop">
                    <div className="content">
                      <h1 className="has-text-weight-bold is-size-4">
                        World Class Laboratories
                      </h1>
                      <p>
                        Pathlab is a New Zealand owned and operated, IANZ
                        accredited medical laboratory. We provide cutting edge
                        services to the Bay of Plenty, Waikato and Lakes
                        regions. Our time proven and reputable systems and
                        processes enable health care for thousands of patients a
                        day.
                      </p>
                    </div>
                    <Tiles tileItems={tiles} />
                  </div>
                  <hr className="is-tablet" />
                  <div className="column is-6-desktop">
                    <div className="content">
                      {/* <h1 className="has-text-weight-bold is-size-4">
                        Find status updates for our main centres on Facebook
                      </h1>
                      <Facebook fbLinks={fbLinks} /> */}
                      <h1 className="has-text-weight-bold is-size-4">
                        Latest News
                      </h1>
                    </div>
                    {posts.map(({ node: post }) => (
                      <div
                        className="content basic-border news-item"
                        key={post.id}
                      >
                        <p>
                          <Link
                            className="has-text-primary news-header"
                            to={post.fields.slug}
                          >
                            <strong>{post.frontmatter.title}</strong>
                          </Link>
                          <br />
                          <small>{post.frontmatter.date}</small>
                        </p>
                        <p>
                          {post.excerpt}
                          <br />
                          <br />
                          <Link
                            className="button is-small is-primary"
                            to={post.fields.slug}
                          >
                            Keep Reading →
                          </Link>
                        </p>
                      </div>
                    ))}
                    <Link className="button is-small is-primary" to="/news">
                      More News →
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      limit: 4
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "news-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
